/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2022-12-20",
    versionChannel: "nightly",
    buildDate: "2022-12-20T00:37:48.783Z",
    commitHash: "9db6ace65f258097d1ed82664b0ef704bf693fdc",
};
